import * as types from './ActionTypes';
import { getCookie, refreshSession, jwtGetUser } from '../utils';

export const setAuthUser = authUser => dispatch => {
  dispatch({ type: types.AUTH_USER_SET, authUser });
};

export const checkAuth = () => dispatch => {
  const authCookie = getCookie('86553b39');

  if (authCookie) {
    refreshSession();
    dispatch(setAuthUser(jwtGetUser(authCookie)));

    return true;
  } else {
    return false;
  }
};
