import React from 'react';

const DoneAllIcon = props => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 5.25l-1.057-1.058-4.755 4.755 1.057 1.058L13.5 5.25zm3.18-1.058l-7.935 7.935L5.61 9l-1.057 1.057 4.192 4.193 9-9-1.065-1.058zM.308 10.057L4.5 14.25l1.058-1.057L1.373 9 .308 10.057z"
      fill="currentColor"
    />
  </svg>
);

export default DoneAllIcon;
