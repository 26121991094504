import {
  decode as jwtDecode,
  verify as jwtVerify,
  getUser as jwtGetUser
} from './jwtUtil';
import moment from 'moment';
import _ from 'lodash';
import { Cookies } from 'react-cookie';


const cookies = new Cookies();

export {
  jwtDecode,
  jwtVerify,
  jwtGetUser
};

// check if array or object is empty
export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

// convert milliseconds timestamp to more readable format
export function makeTimeReadable(date) {
  return {
    date: moment.utc(date, 'X').format('MM/DD/YY'),
    time: moment.utc(date, 'X').format('h:mm a')
  };
}

// sort by key
export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

// get value of object by path. Path can be nested. example: resolvePropDepth('name.first', obj)
export function resolvePropDepth(path, obj) {
  return path.split('.').reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || this);
}

// truncate string with max character count
export function truncate(string, max) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}

// get cookie by name
export function getCookie(name) {
  return cookies.get(name);
}

// update auth cookie
export function refreshSession() {
  const value = cookies.get('86553b39');
  const newDate = moment().add(30, 'minutes').toDate();

  cookies.set('86553b39', value, {
    path: '/',
    expires: newDate,
    domain: '.parkhub.com'
  });
}

//remove auth cookie and custom GTM cookie
export function deleteCookie() {
  cookies.remove('86553b39', {
    domain: '.parkhub.com'
  });
  cookies.remove('_gtmUID', {
    domain: '.parkhub.com'
  });
}

// Sign out user and redirect to suite login
export function signOut() {
  deleteCookie();
  window.location.replace(`${process.env.REACT_APP_LOGIN_URL}?redirectUrl=${window.location.href}`);
}
