import React from 'react';

const PuckIcon = props => (
  <svg
    width="44"
    height="33"
    fill="none"
    {...props}
  >
    <path
      d="M0 20.48v2.9L14.4 33v-4.5L0 20.49z"
      fill="#787780"
    />
    <path
      d="M44 24.07v-3.16l-29.6 7.6V33L44 24.07z"
      fill="#57565C"
    />
    <path
      d="M4.7 17.68l11.41 3.87-1.71 6.96L0 20.48l4.7-2.8z"
      fill="#85848C"
    />
    <path
      d="M37.76 17.68L16.1 21.55 14.4 28.5 44 20.9l-6.24-3.23z"
      fill="#68676D"
    />
    <path
      d="M22.75 15.36l15 2.32-21.64 3.87L4.7 17.68l18.05-2.32z"
      fill="#939299"
    />
    <path
      d="M26.94 13.33a.76.76 0 0 1-.52-.22 5.48 5.48 0 0 0-7.95 0c-.28.3-.75.3-1.03 0a.78.78 0 0 1 0-1.07 6.93 6.93 0 0 1 10.03 0c.28.3.28.78 0 1.07a.78.78 0 0 1-.53.22z"
      fill="#67CADB"
    />
    <path
      d="M29.19 11a.76.76 0 0 1-.52-.22 8.59 8.59 0 0 0-12.45 0c-.28.3-.75.3-1.03 0a.78.78 0 0 1 0-1.07c4-4.15 10.51-4.15 14.51 0 .29.29.29.77 0 1.07a.72.72 0 0 1-.51.22z"
      fill="#67CADB"
    />
    <path
      d="M31.43 8.67a.76.76 0 0 1-.52-.22 11.69 11.69 0 0 0-16.93 0c-.29.3-.75.3-1.04 0a.78.78 0 0 1 0-1.07 13.12 13.12 0 0 1 19.01 0c.29.3.29.78 0 1.07a.74.74 0 0 1-.52.22z"
      fill="#67CADB"
    />
    <path
      d="M33.68 6.33a.76.76 0 0 1-.52-.21 14.79 14.79 0 0 0-21.43 0c-.29.29-.76.29-1.04 0a.78.78 0 0 1 0-1.08 16.22 16.22 0 0 1 23.5 0c.29.3.29.78 0 1.08a.74.74 0 0 1-.5.21z"
      fill="#67CADB"
    />
  </svg>
);
export default PuckIcon;
