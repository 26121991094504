import './LoadingSpinner.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import disableScroll from 'disable-scroll';

export class LoadingSpinner extends Component {

  static get propTypes() {
    return {
      show: PropTypes.bool,
      delay: PropTypes.number
    };
  }

  static get defaultProps() {
    return {
      delay: 2000,
      show: true
    };
  }

  state = {
    showSpinner: this.props.show
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.show && !this.props.show) {
      this.timeout = setTimeout(
        () => this.setSpinner(false),
        this.props.delay
      );
    } else if (!prevProps.show && this.props.show) {
      this.setSpinner(true);
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  }

  setSpinner = (value) => {
    this.setState(
      ()=> ({ showSpinner: value }),
      ()=> value ? disableScroll.on() : disableScroll.off()
    );
  }

  render() {
    return (
      <div className="loading-spinner-container" style={{ display: this.state.showSpinner ? 'block' : 'none' }}>
        <div className="loading-spinner-dual-ring" />
      </div>
    );
  }
};
