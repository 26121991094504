import jwt from 'jsonwebtoken';
const jwtSecret = process.env.REACT_APP_AUTH_SECRET;

/**
 * @function decode
 * @description Decodes token.
 * @param {String} token
 * @return {Object} decoded token
 */
export function decode (token) {
  return jwt.decode(token, { complete: true });
}

/**
 * @function verify
 * @description Ensures validity of the token.
 * @param {String} token
 * @return {Boolean}
 */
export function verify (token) {
  return jwt.verify(token, jwtSecret, { algorithms: ['HS256'] });
}

/**
 * @function getUser
 * @description Returns the user information from the token
 * @param {String} token
 * @return {Object} decoded user
 */
export function getUser (token) {
  if (token) {
    return verify(token).payload;
  }
  return null;
}

