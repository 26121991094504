import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogContainer } from 'react-md';
import { signOut, refreshSession } from '../../utils/';
import Timer from 'react-compound-timer';

class SessionDialog extends Component {

  continue = () => {
    refreshSession();
    this.props.onContinue();
    this.props.onHide();
  }

  signOut = () => {
    signOut();
    this.props.onHide();
  }

  render() {
    const { visible, onHide } = this.props;

    return (
      <DialogContainer
        id="session-dialog"
        visible={visible}
        onHide={onHide}
        aria-describedby="session expire"
        modal
        portal
        lastChild
        actions={[{
          onClick: this.continue,
          primary: true,
          children: 'Continue',
        }, {
          onClick: this.signOut,
          primary: true,
          children: 'Sign out',
        }]}
      >
        <p id="session expire" className="md-color--secondary-text">
          Your session is about to expire!
          <br />
          <Timer
            initialTime={60000} // 60 seconds
            direction="backward"
            checkpoints={[
              {
                time: 0,
                callback: () => this.signOut(),
              }
            ]}
          >
            <Timer.Seconds /> seconds
          </Timer>
        </p>
      </DialogContainer>
    );
  }
};

SessionDialog.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export default SessionDialog;
