import React from 'react';

const CashIcon = props => (
  <svg viewBox="0 0 20 12" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.99951172 0h-20v12h20V0zm-19 2.71313V1h1.79199c-.20996.85229-.9082 1.52075-1.79199 1.71313zm0 .93946V8.3479c1.41064.21582 2.52686 1.28931 2.76318 2.6521h12.47362c.2359-1.36279 1.3521-2.43652 2.7632-2.65234V3.65283c-1.4111-.21582-2.5278-1.28955-2.7637-2.65283h-12.47263c-.23584 1.36328-1.35303 2.43677-2.76367 2.65259zM17.20701172 1c.2105.85229.9087 1.52075 1.7925 1.71313V1h-1.7925zm1.7925 8.28735c-.8838.19239-1.5815.86055-1.792 1.71265h1.792V9.28735zM2.79101172 11c-.21045-.8521-.90771-1.52026-1.7915-1.71265V11h1.7915zm7.6851-9.15381v.81275c1.0029.10717 1.5283.63134 1.5283.63134l-.4248.75074s-.6143-.5586-1.4649-.5586c-.70846 0-1.20895.43042-1.20895.94336 0 .59351.63672.85327 1.34525 1.14185.8867.36157 1.8857.76904 1.8857 1.93506 0 .87768-.6133 1.6206-1.6606 1.78735v.86376h-.95219v-.86449c-1.10498-.16187-1.71435-.81592-1.71435-.81592l.5293-.68677s.70849.6958 1.70064.6958c.6236 0 1.1524-.33862 1.1524-.95239 0-.63941-.6729-.92407-1.4048-1.2334-.87158-.36865-1.82617-.77246-1.82617-1.85254 0-.84082.62548-1.53491 1.56298-1.74145v-.85645h.95219z"
    />
  </svg>
);

export default CashIcon;
