import React, { Component, Fragment } from 'react';
import ParkhubLogo from './parkhub-logo-white.svg';
import { AppDrawerButton, UserButton } from '../';

export class DesktopHeader extends Component {

  render() {
    return (
      <Fragment>
        <header id="header" ref="header" className="header-desktop">
          <div className="logo-container">
            <img src={ParkhubLogo} alt="parkhub logo" />
          </div>
          <div className="desktop-header-actions">
            {!!this.props.authUser &&
              <Fragment>
                <AppDrawerButton id="desktop-header-app-drawer" className="desktop-header-action-child" />
                <UserButton id="desktop-header-user-menu" className="desktop-header-action-child" authUser={this.props.authUser} />
              </Fragment>
            }
          </div>
        </header>
      </Fragment>
    );
  }
}

export default DesktopHeader;
