import React from 'react';

const AlertIcon = props => (
  <svg
    viewBox="0 0 16 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 20a2.03 2.03 0 0 0 1.84-1.22 2 2 0 0 0 .15-.78h-4c.01 1.1.9 2 2.01 2zm6-11c0-3.07-1.64-5.64-4.5-6.32V2a1.5 1.5 0 1 0-3 0v.68C3.63 3.36 2 5.92 2 9v5l-2 2v1h16v-1l-2-2V9z"
      fill="currentColor"
    />
  </svg>
);

export default AlertIcon;
