import React from 'react';

const FlagIcon = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6z"
      clipRule="evenodd"
    />
  </svg>
);

export default FlagIcon;
