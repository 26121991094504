import React from 'react';

const CreditCardIcon = props => (
  <svg viewBox="0 0 20 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 10V5H1v5c0 .5527.4478 1 1 1h14a.9888.9888 0 0 0 .3654-.0693.9888.9888 0 0 0 .293-.1778A.9958.9958 0 0 0 17 10zm0-8v1H1V2c0-.3486.1785-.6553.4491-.835A.9948.9948 0 0 1 2 1h14c.5522 0 1 .4473 1 1zm1 8V2c0-1.1045-.8954-2-2-2H2C.8954 0 0 .8955 0 2v8c0 1.1045.8954 2 2 2h14c1.1046 0 2-.8955 2-2zM2.5 9c-.2761 0-.5.2236-.5.5s.2239.5.5.5h6c.2761 0 .5-.2236.5-.5S8.7761 9 8.5 9h-6z"
      fill="currentColor"
    />
  </svg>
);

export default CreditCardIcon;
