import React from 'react';

const PSpot = props => (
  <svg
    version="1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    height="60"
  >
    <path
      fill="#ff6536"
      d="M0 0h1024v1024H0z"
    />
    <path
      d="M512 211a256 256 0 0 0-256 256v383h128V688a256 256 0 1 0 128-477zm0 382a128 128 0 1 1 128-128c0 71-57 128-128 128z"
      fill="#fff"
    />
  </svg>
);
export default PSpot;
