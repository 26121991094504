import React from 'react';

const Week = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 12h-5v5h5v-5zm-5 0H7v5h5v-5zm4-11v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
      clipRule="evenodd"
    />
  </svg>
);

export default Week;
