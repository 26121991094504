// Do not include this page in a production project

import React, { Component } from 'react';
import * as icons from '../../assets/react-icons';

function importToArray(importObject) {
  const keys = Object.getOwnPropertyNames(importObject);
  return keys.filter(key => key.indexOf("__") !== 0).map(key => importObject[key]);
}

class IconsContainer extends Component {

  render() {
    return (
      <main id="icons-container">
        <div className="wrapper">
          <div className="row">
            <div className="col-xs">
              <p>
                There are 2 kinds of icons included in the project, custom react-icons and material Icons.
              </p>
              <p>
                Custom react-icons are SVGs that are implemented as React components. They can take any SVG attribute as a prop.
              </p>
              <p>
                <a target="_blank" rel="noopener noreferrer" href="https://material.io/resources/icons/">Material icons</a> can be implemented in multiple ways.
                I <strong>strongly</strong> suggest trying these before attempting to implement your own.
                <br />
                React-md treats them as first class citizens in their components, such as <a target="_blank" rel="noopener noreferrer" href="https://react-md.mlaursen.com/components/buttons">Buttons</a> and <a target="_blank" rel="noopener noreferrer" href="https://react-md.mlaursen.com/components/font-icons">FontIcons</a>.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs">
              <p>
                Example usage of a custom react-icon:
              </p>
              <pre>
                <code>
                  {`<PersonIcon color="#000000" width="24px" height="24px" />`}
                </code>
              </pre>
            </div>
          </div>
          <div className="row center-xs">
            {importToArray(icons).map((icon, index) =>
              <div key={index} className="col-xs-4">
                <div className="icon-item">
                  {icon({width: '50px'})}
                </div>
                <h6>{icon.name}</h6>
              </div>
            )}
          </div>
        </div>
      </main>
    );
  }
};

export default IconsContainer;
