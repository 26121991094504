import React, { Component } from 'react';
import './AppMenu.scss';
import { Button } from 'react-md';
import {
  AnalyticsIcon,
  CashRegisterIcon,
  GroupIcon,
  // BarcodeIcon,
  // LightBulbIcon,
  // MapIcon,
  // PuckIcon,
  // SmartphoneIcon,
  // WallCalendarIcon
} from '../../assets/react-icons';

export default class AppMenu extends Component {

  checkRole = (allowedRoles) => {
    const user = this.props.authUser;
    const roles = user.g.map(org => org.r);

    let check = false;

    roles.forEach(role => {
      if (allowedRoles.includes(role)) {
        check = true;
      }
    });

    return check;
  };

  render() {
    return (
      <div className='app-container'>
        {this.checkRole(['GLOBAL_ADMIN', 'OPERATOR']) && (
          <Button flat className='app-icon' href={process.env.REACT_APP_PORTAL_URL}>
            <CashRegisterIcon />
            <div className='app-name'>Portal</div>
          </Button>
        )}
        {/* <Button flat className='app-icon'>
          <WallCalendarIcon />
          <div className='app-name'>Schedule</div>
        </Button> */}
        {this.checkRole(['GLOBAL_ADMIN','OPERATOR','VIEWER']) && (
          <Button flat className='app-icon' href={process.env.REACT_APP_ANALYTICS_URL}>
            <AnalyticsIcon />
            <div className='app-name'>Analytics</div>
          </Button>
        )}
        {/* <Button flat className='app-icon'>
          <SmartphoneIcon />
          <div className='app-name'>Devices</div>
        </Button> */}
        {/* <Button flat className='app-icon'>
          <PuckIcon />
          <div className='app-name'>Pulse</div>
        </Button> */}
        {/* <Button flat className='app-icon'>
          <MapIcon />
          <div className='app-name'>Facility</div>
        </Button> */}
        {this.checkRole(['GLOBAL_ADMIN']) && (
          <Button flat className='app-icon' href={process.env.REACT_APP_TEAM_URL}>
            <GroupIcon />
            <div className='app-name'>Team</div>
          </Button>
        )}
        {/* <Button flat className='app-icon'>
          <LightBulbIcon />
          <div className='app-name'>Education</div>
        </Button>
        <Button flat className='app-icon'>
          <BarcodeIcon />
          <div className='app-name'>Barcode</div>
        </Button> */}
        <Button flat className='app-container-view-all' href={process.env.REACT_APP_LAUNCHER_URL}>view all apps</Button>
      </div>
    );
  }
}
