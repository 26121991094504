import React from 'react';

const RoundCheckmark = props => (
  <svg fill="none" viewBox="0 0 25 25" {...props}>
    <circle cx="12.5" cy="12.5" r="12.5" fill="currentColor" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M8 12.81818L10.88 16 17 9"
    />
  </svg>
);

export default RoundCheckmark;
