import React from 'react';

const ShoppingCartIcon = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 18a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM2 2v2h2l4 8-2 2v1l2 2h12v-2H8l1-2h8l1-1 4-7-1-1H6L5 2H2zm16 16a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
      fill="currentColor"
    />
  </svg>
);

export default ShoppingCartIcon;

