import React from 'react';

const MapIcon = props => (
  <svg
    width="34"
    height="34"
    fill="none"
    {...props}
  >
    <path
      d="M26.58 30.367c1.461.637 5.501 2.468 6.937 3.052.159.08.531.053.478-.292-.32-3.45-2.26-23.226-2.605-26.73-.053-.504-.213-.584-.585-.716a383.068 383.068 0 0 0-5.05-1.805c-.398-.16-.637-.16-1.063 0-1.78.504-7.415 2.23-7.415 2.23s-5.874-1.78-7.707-2.284c-.4-.132-.612-.159-1.064.027-1.169.372-3.88 1.38-5.05 1.805-.478.186-.531.398-.584.717C2.5 9.795.374 29.65.002 33.127c-.027.398.318.372.637.266 1.595-.637 5.635-2.39 7.07-2.973.292-.133.585-.213 1.063 0 1.621.663 6.379 2.813 7.947 3.45.346.16.665.186 1.063 0 1.568-.663 6.166-2.787 7.708-3.477.292-.16.744-.186 1.09-.026z"
      fill="#F4F0D3"
    />
    <path
      d="M9.224 3.742c-.213-.026-.425 0-.718.107-1.169.371-3.88 1.38-5.05 1.805-.478.185-.531.398-.584.716C2.5 9.795.374 29.65.002 33.127c-.027.398.318.371.637.265 1.595-.637 5.635-2.389 7.07-2.973.213-.08.399-.159.691-.106l.824-26.57zm15.867 0a2.478 2.478 0 0 0-.345.107c-1.781.53-7.203 2.176-7.442 2.256v27.871c.16 0 .345-.053.531-.133 1.569-.663 6.166-2.787 7.708-3.477.106-.053.24-.08.372-.106l-.824-26.518z"
      fill="#E8E4C8"
    />
    <path
      d="M12.414 31.985l4.65-4.645 4.732 4.725c.983-.451 1.94-.903 2.71-1.248L7.524 13.882l8.24-8.229c-.851-.265-1.967-.583-3.03-.902l-7.177 7.167-2.95-2.946c-.106 1.008-.239 2.23-.372 3.583l12.864 12.847-5.395 5.389c.744.318 1.727.743 2.71 1.194z"
      fill="#EFC75E"
    />
    <path
      d="M8.958 12.449l.133-4.061-3.535 3.504-2.95-2.92c-.106 1.008-.239 2.23-.372 3.583l6.538 6.53.107-3.849-1.33-1.327 1.41-1.46zm8.32 15.13l4.491 4.486c.983-.451 1.94-.903 2.711-1.248l-7.203-7.193v3.955z"
      fill="#E3BD59"
    />
    <path
      d="M21.264 0c4.252 0 7.707 3.45 7.707 7.724 0 .08-.026 2.07-.69 3.478-1.569 3.636-5.343 7.485-6.592 8.733-.32.292-.452.345-.85-.027-1.276-1.248-4.997-5.043-6.565-8.653-.559-1.062-.718-3.345-.718-3.53C13.556 3.45 17.01 0 21.264 0z"
      fill="#E2574C"
    />
    <path
      d="M21.264 3.716a3.979 3.979 0 0 0-3.986 3.982 3.979 3.979 0 0 0 3.986 3.982 3.979 3.979 0 0 0 3.987-3.982 3.979 3.979 0 0 0-3.987-3.982zm0 6.37a2.38 2.38 0 0 1-2.392-2.388 2.38 2.38 0 0 1 2.392-2.389 2.38 2.38 0 0 1 2.392 2.389 2.38 2.38 0 0 1-2.392 2.389z"
      fill="#fff"
    />
  </svg>
);

export default MapIcon;
