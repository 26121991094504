import React from 'react';

const AnalyticsIcon = props => (
  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path
      d="M21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21z"
      fill="#324A5E"
    />
    <path
      d="M11.892 19.544l-1.04-.798 6.812-8.832 6.637 6.42 6.296-5.031.815 1.022-7.194 5.756-6.413-6.205-5.913 7.668z"
      fill="#CED5E0"
    />
    <path
      d="M3.609 32.777C7.385 38.34 13.764 42 20.999 42c7.236 0 13.615-3.66 17.39-9.223H3.61z"
      fill="#2B3B4E"
    />
    <path
      d="M32.834 32.053h-4.1a.17.17 0 0 1-.175-.174V17.016c0-.1.075-.174.175-.174h4.1c.1 0 .175.075.175.175v14.862a.176.176 0 0 1-.175.174zM26.314 32.053h-4.1a.17.17 0 0 1-.175-.174V21.383c0-.1.075-.175.175-.175h4.1c.1 0 .175.075.175.175v10.495c-.009.092-.083.175-.175.175zM19.784 32.053h-4.1a.17.17 0 0 1-.175-.174v-15.81c0-.1.075-.175.175-.175h4.1c.1 0 .175.074.175.174v15.81a.176.176 0 0 1-.175.175zM13.265 32.053h-4.1a.17.17 0 0 1-.175-.175v-7.793c0-.1.075-.174.174-.174h4.1c.1 0 .175.074.175.174v7.793a.181.181 0 0 1-.174.175z"
      fill="#54C0EB"
    />
    <path
      d="M35.903 31.355H6.095v1.422h29.808v-1.422z"
      fill="#CED5E0"
    />
    <path
      d="M31.005 14.263a2.445 2.445 0 1 0 0-4.89 2.445 2.445 0 0 0 0 4.89z"
      fill="#E6E9EE"
    />
    <path
      d="M31.005 13.24a1.422 1.422 0 1 0 0-2.844 1.422 1.422 0 0 0 0 2.844z"
      fill="#FF7058"
    />
    <path
      d="M24.26 19.411a2.445 2.445 0 1 0 0-4.89 2.445 2.445 0 0 0 0 4.89z"
      fill="#E6E9EE"
    />
    <path
      d="M24.26 18.388a1.422 1.422 0 1 0 0-2.844 1.422 1.422 0 0 0 0 2.844z"
      fill="#FF7058"
    />
    <path
      d="M17.74 13.34a2.445 2.445 0 1 0 0-4.89 2.445 2.445 0 0 0 0 4.89z"
      fill="#E6E9EE"
    />
    <path
      d="M17.739 12.317a1.422 1.422 0 1 0 0-2.844 1.422 1.422 0 0 0 0 2.844z"
      fill="#FF7058"
    />
    <path
      d="M11.21 21.59a2.445 2.445 0 1 0 0-4.89 2.445 2.445 0 0 0 0 4.89z"
      fill="#E6E9EE"
    />
    <path
      d="M11.21 20.567a1.422 1.422 0 1 0 0-2.844 1.422 1.422 0 0 0 0 2.845z"
      fill="#FF7058"
    />
  </svg>

);

export default AnalyticsIcon;
