import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Home extends Component {

  render() {
    return (
      <div className="row center-xs">
        <div className="col-xs-9">
          <h1>React Starter Kit</h1>
          <h6>by Parkhub</h6>
          <p>
            <Link to="/icons">Icons documentation</Link>
          </p>
        </div>
      </div>
    );
  }
};

export default Home;
