import React from 'react';

const PHWordMark = props => (
  <svg
    width="267"
    height="52"
    fill="none"
  >
    <path
      d="M57 9a16 16 0 0 0-13 6 18 18 0 0 0 0 22 17 17 0 0 0 14 6l7-2v2h9V26a18 18 0 0 0-5-12 17 17 0 0 0-12-5zm0 26l-5-2-3-4a9 9 0 0 1 2-9 8 8 0 0 1 9-2l4 3a9 9 0 0 1-1 11l-6 3zM109 14l-6 6a8 8 0 0 0-9-2l-4 3-1 5v17h-8V9h8v2a16 16 0 0 1 20 3zM17 9c-5 0-9 1-12 5-3 3-5 7-5 12v26h8V41a16 16 0 0 0 21-4 18 18 0 0 0 4-14 18 18 0 0 0-9-13l-7-1zm0 26l-5-2-3-4a9 9 0 0 1 2-9 8 8 0 0 1 9-2l4 3a9 9 0 0 1-1 11l-6 3zM186 26v17h-8V26l-2-6a8 8 0 0 0-12 0c-2 1-3 4-3 6v17h-8V0h8v11a16 16 0 0 1 23 6c2 3 2 6 2 9zM227 9v17c0 5-2 9-5 12a17 17 0 0 1-24 0c-3-3-5-7-5-12V9h9v17c0 2 0 5 2 6a8 8 0 0 0 12 0l2-6V9h9zM250 9c-3 0-6 0-8 2V0h-9v26c0 3 1 7 3 10l8 6a16 16 0 0 0 18-4 18 18 0 0 0 4-19l-6-7c-3-2-6-3-10-3zm0 26l-4-2-4-4a9 9 0 0 1 2-9 8 8 0 0 1 9-2l4 3a9 9 0 0 1-1 11c-1 2-4 3-6 3zM147 43h-12l-12-13v13h-9V0h9v22l12-13h12l-17 17 17 17z"
      fill="#DADADA"
    />
  </svg>
);

export default PHWordMark;
