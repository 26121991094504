import React from 'react';

const TransactionIcon = props => (
  <svg viewBox="0 0 20 14" {...props}>
    <path
      fill="currentColor"
      d="M3.33304 0c-.94448 0-1.66673.758333-1.66673 1.75v6.76644l-.722693-.75765c-.222231-.23333-.554711-.23333-.776943 0-.2222319.23333-.2222318.58242 0 .81575L1.83341 10.3245c.11112.1167.2218.1755.38847.1755.16668 0 .27736-.0588.38848-.1755L4.2771 8.57454c.22223-.23333.22223-.58242 0-.81575-.22224-.23333-.55472-.23333-.77695 0l-.72269.75765V1.75c0-.35.22224-.58333.55558-.58333H17.7781c.3334 0 .5556-.233337.5556-.583337S18.1115 0 17.7781 0H3.33304zM17.7781 3.5c-.1389 0-.2773.05879-.3885.17546l-1.6667 1.75c-.2222.23333-.2222.58242 0 .81575.2222.23333.5547.23333.7769 0l.7227-.75765V12.25c0 .35-.2222.5833-.5555.5833H2.22188c-.33334 0-.55557.2334-.55557.5834 0 .35.22223.5833.55557.5833H16.667c.9444 0 1.6667-.7583 1.6667-1.75V5.48356l.7227.75765c.1111.11667.2773.17546.3885.17546.1111 0 .2773-.05879.3884-.17546.2223-.23333.2223-.58242 0-.81575l-1.6667-1.75C18.0555 3.55879 17.917 3.5 17.7781 3.5z"
    />
  </svg>
);

export default TransactionIcon;
