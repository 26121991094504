import React from 'react';

const PrinterIcon = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 8H5a3 3 0 0 0-3 3v6h4v4h12v-4h4v-6a3 3 0 0 0-3-3zm-3 11H8v-5h8v5zm3-7a1 1 0 0 1-1-1c0-.6.4-1 1-1 .5 0 1 .4 1 1s-.5 1-1 1zm-1-9H6v4h12V3z"
      fill="currentColor"
    />
  </svg>
);

export default PrinterIcon;

