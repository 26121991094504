import './HeaderDropDown.scss';
import React, { Component } from 'react';
import { MenuButton } from 'react-md';
import './HeaderDropDown.scss';
import AppMenu from '../AppMenu/AppMenu';
import UserMenu from '../UserMenu/UserMenu';

export class HeaderDropDown extends Component {

  componentDidMount() {
    window.addEventListener('scroll', this.props.handleScroll);
  }

  render() {
    const { dropDownType, idName } = this.props;

    return (
      <MenuButton
        id={idName || 'header-dropdown'}
        flat
        simplifiedMenu={false}
        className="header-menu-btn"
        primary
        transitionEnterTimeout={0}
        listStyle={{
          top: '76px',
          right: '12px',
          left: 'auto',
          maxHeight: '362px'
        }}
        menuItems={dropDownType === 'app-picker' ? <AppMenu /> : <UserMenu />}
        repositionOnScroll={false}
      >
        {this.props.children}
      </MenuButton>
    );
  }
}

export default HeaderDropDown;
