import React from 'react';

const FilterIcon = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g data-name="Canvas">
      <g data-name="filter icon" clipRule="evenodd" fill="none">
        <g data-name="Subtract">
          <path
            fillRule="evenodd"
            d="M11.476 5.5l-.118-.333a2.501 2.501 0 0 0-4.716 0l-.118.333H4a.5.5 0 0 0 0 1h2.524l.118.333a2.501 2.501 0 0 0 4.716 0l.118-.333H20a.5.5 0 0 0 0-1h-8.524zm-.69.5a1.786 1.786 0 1 1-3.572 0 1.786 1.786 0 0 1 3.572 0z"
            fill="currentColor"
          />
          <path
            d="M11.476 5.5l-.118-.333a2.501 2.501 0 0 0-4.716 0l-.118.333H4a.5.5 0 0 0 0 1h2.524l.118.333a2.501 2.501 0 0 0 4.716 0l.118-.333H20a.5.5 0 0 0 0-1h-8.524zm-.69.5a1.786 1.786 0 1 1-3.572 0 1.786 1.786 0 0 1 3.572 0z"
            strokeMiterlimit={8}
            stroke="currentColor"
          />
        </g>
        <g data-name="Subtract">
          <path
            fillRule="evenodd"
            d="M18.476 12.5l-.118-.333a2.501 2.501 0 0 0-4.716 0l-.118.333H4a.5.5 0 0 0 0 1h9.524l.118.333a2.501 2.501 0 0 0 4.716 0l.118-.333H20a.5.5 0 0 0 0-1h-1.524zm-.69.5a1.786 1.786 0 1 1-3.572 0 1.786 1.786 0 0 1 3.572 0z"
            fill="currentColor"
          />
          <path
            d="M18.476 12.5l-.118-.333a2.501 2.501 0 0 0-4.716 0l-.118.333H4a.5.5 0 0 0 0 1h9.524l.118.333a2.501 2.501 0 0 0 4.716 0l.118-.333H20a.5.5 0 0 0 0-1h-1.524zm-.69.5a1.786 1.786 0 1 1-3.572 0 1.786 1.786 0 0 1 3.572 0z"
            strokeMiterlimit={8}
            stroke="currentColor"
          />
        </g>
        <g data-name="Subtract">
          <path
            fillRule="evenodd"
            d="M11.476 19.5l-.118-.333a2.501 2.501 0 0 0-4.716 0l-.118.333H4a.5.5 0 0 0 0 1h2.524l.118.333a2.501 2.501 0 0 0 4.716 0l.118-.333H20a.5.5 0 0 0 0-1h-8.524zm-.69.5a1.786 1.786 0 1 1-3.572 0 1.786 1.786 0 0 1 3.572 0z"
            fill="currentColor"
          />
          <path
            d="M11.476 19.5l-.118-.333a2.501 2.501 0 0 0-4.716 0l-.118.333H4a.5.5 0 0 0 0 1h2.524l.118.333a2.501 2.501 0 0 0 4.716 0l.118-.333H20a.5.5 0 0 0 0-1h-8.524zm-.69.5a1.786 1.786 0 1 1-3.572 0 1.786 1.786 0 0 1 3.572 0z"
            strokeMiterlimit={8}
            stroke="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default FilterIcon;
