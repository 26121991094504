import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import { Button } from 'react-md';
import { ExitLeftIcon } from '../../assets/react-icons';
import { signOut } from '../../utils';

const INITIAL_STATE = {
  cookies: new Cookies()
};

class SignOutButton extends Component {
  state = { ...INITIAL_STATE };

  handleSignOut = () => {
    signOut();
  };

  render() {
    return (
      <Button onClick={this.handleSignOut} flat className={!this.props.mobile ? 'user-bottom-menu-items' : 'mobile-sign-out-button'}>
        <div className={!this.props.mobile ? 'user-bottom-menu-icon' : 'mobile-sign-out-icon'}>
          <ExitLeftIcon height="24px" width="24px" color="rgba(0,0,0,.5)" />
        </div>
        <div className={this.props.mobile && 'md-tile-text--primary'}>Sign out</div>
      </Button>
    );
  }
}

export default SignOutButton;
