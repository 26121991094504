import React from 'react';

const HelpOutlineIcon = props => (
  <svg viewBox="0 0 24 24"  {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 18h2v-2h-2v2zm1-16a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-14a4 4 0 0 0-4 4h2c0-1.1.9-2 2-2a2 2 0 0 1 2 2c0 2-3 1.8-3 5h2c0-2.3 3-2.5 3-5a4 4 0 0 0-4-4z"
      fill="currentColor"
    />
  </svg>
);

export default HelpOutlineIcon;
