import './Layout.scss';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Header } from '../../components';
import Home from '../Home/Home';
import IconsContainer from '../IconsContainer/IconsContainer';

class Layout extends Component {

  render() {
    const { authUser } = this.props;

    return (
      <Fragment>
        <Header authUser={authUser} />
        <main id="layout">
          <div className="wrapper">
            <Switch>
              <Route exact path={`/`} render={props => <Home {...props} />} />
              {/* Do not include `IconsContainer` in production */}
              <Route path={`/icons`} render={props => <IconsContainer {...props} />} />
            </Switch>
          </div>
        </main>
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  authUser: state.session.authUser
});

export default connect(
  mapStateToProps,
  {}
)(Layout);
