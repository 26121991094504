import './UserMenu.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TuneIcon } from '../../assets/react-icons';
import { Button, FontIcon } from 'react-md';
import SignOutButton from '../SignOutButton/SignOutButton';

class UserMenu extends Component {
  render() {
    const { authUser } = this.props;

    return (
      <div className="user-menu-container">
        <div className="user-menu-top">
          <div className="user-avatar">
            {!!authUser && `${authUser.fn[0]}${authUser.fn[0]}`}
          </div>
          <div className="user-details">
            <div className="user-name">
              {!!authUser && `${authUser.fn} ${authUser.ln}`}
            </div>
            <div className="user-email">
              {!!authUser && `${authUser.e}`}
            </div>
          </div>
        </div>
        <div className="user-menu-bottom">
          <SignOutButton id="user-dropdown-sign-out" />
          <Button id="user-dropdown-acct-settings" flat className="user-bottom-menu-items">
            <div className="user-bottom-menu-icon">
              <TuneIcon height="24px" width="24px" color="rgba(0,0,0,.5)" />
            </div>
            <div>ACCOUNT SETTINGS</div>
          </Button>
          <Button id="user-dropdown-team-settings" flat className="user-bottom-menu-items">
            <div className="user-bottom-menu-icon">
              <FontIcon>group</FontIcon>
            </div>
            <div>TEAM SETTINGS</div>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.session.authUser
});

export default connect(mapStateToProps)(UserMenu);
