import React from 'react';

const WallCalendarIcon = props => (
  <svg
    width="36"
    height="36"
    fill="none"
    {...props}
  >
    <path
      d="M34.76 32.28H1.24c-.68 0-1.24-.56-1.24-1.25V2.48c0-.68.56-1.24 1.24-1.24h33.52c.68 0 1.24.56 1.24 1.24v28.55c0 .69-.56 1.25-1.24 1.25z"
      fill="#EFF2FA"
    />
    <path
      d="M26.6 32.28a9.93 9.93 0 1 0-17.18 0h17.17z"
      fill="#E4EAF6"
    />
    <path
      d="M36 7.45H0V2.48c0-.68.56-1.24 1.24-1.24h33.52c.68 0 1.24.56 1.24 1.24v4.97z"
      fill="#FF6464"
    />
    <path
      d="M36 7.45H0v1.24h36V7.45z"
      fill="#E4EAF6"
    />
    <path
      d="M5.28 5.9a1.86 1.86 0 1 0 0-3.73 1.86 1.86 0 0 0 0 3.73z"
      fill="#D2555A"
    />
    <path
      d="M5.28 4.97a.93.93 0 0 1-.93-.94V.93a.93.93 0 0 1 1.86 0v3.1c0 .52-.42.94-.93.94z"
      fill="#C7CFE2"
    />
    <path
      d="M5.28 3.1a.93.93 0 0 1-.93-.93v1.86a.93.93 0 0 0 1.86 0V2.17c0 .52-.42.93-.93.93z"
      fill="#AFB9D2"
    />
    <path
      d="M30.72 5.9a1.86 1.86 0 1 0 0-3.73 1.86 1.86 0 0 0 0 3.73z"
      fill="#D2555A"
    />
    <path
      d="M30.72 4.97a.93.93 0 0 1-.93-.94V.93a.93.93 0 1 1 1.86 0v3.1c0 .52-.41.94-.93.94z"
      fill="#C7CFE2"
    />
    <path
      d="M30.72 3.1a.93.93 0 0 1-.93-.93v1.86a.93.93 0 0 0 1.86 0V2.17c0 .52-.41.93-.93.93z"
      fill="#AFB9D2"
    />
    <path
      d="M6.83 16.14H4.34a.62.62 0 0 1-.62-.62v-1.87c0-.34.28-.62.62-.62h2.49c.34 0 .62.28.62.62v1.87c0 .34-.28.62-.62.62zM11.8 16.14H9.3a.62.62 0 0 1-.62-.62v-1.87c0-.34.28-.62.62-.62h2.48c.35 0 .62.28.62.62v1.87c0 .34-.27.62-.62.62zM16.76 16.14h-2.48a.62.62 0 0 1-.62-.62v-1.87c0-.34.27-.62.62-.62h2.48c.34 0 .62.28.62.62v1.87c0 .34-.28.62-.62.62zM21.72 16.14h-2.48a.62.62 0 0 1-.62-.62v-1.87c0-.34.28-.62.62-.62h2.48c.35 0 .63.28.63.62v1.87c0 .34-.28.62-.63.62z"
      fill="#E4EAF6"
    />
    <path
      d="M26.69 16.14h-2.48a.62.62 0 0 1-.62-.62v-1.87c0-.34.27-.62.62-.62h2.48c.34 0 .62.28.62.62v1.87c0 .34-.28.62-.62.62zM31.65 16.14h-2.48a.62.62 0 0 1-.62-.62v-1.87c0-.34.28-.62.62-.62h2.48c.35 0 .62.28.62.62v1.87c0 .34-.27.62-.62.62z"
      fill="#D7DEED"
    />
    <path
      d="M6.83 11.8H4.34a.62.62 0 0 1 0-1.25h2.49a.62.62 0 0 1 0 1.24zM11.8 11.8H9.3a.62.62 0 0 1 0-1.25h2.48a.62.62 0 0 1 0 1.24zM16.76 11.8h-2.48a.62.62 0 0 1 0-1.25h2.48a.62.62 0 0 1 0 1.24zM21.72 11.8h-2.48a.62.62 0 0 1 0-1.25h2.48a.62.62 0 0 1 0 1.24z"
      fill="#C7CFE2"
    />
    <path
      d="M26.69 11.8h-2.48a.62.62 0 0 1 0-1.25h2.48a.62.62 0 0 1 0 1.24zM31.65 11.8h-2.48a.62.62 0 0 1 0-1.25h2.48a.62.62 0 0 1 0 1.24z"
      fill="#FF6464"
    />
    <path
      d="M6.83 20.48H4.34a.62.62 0 0 1-.62-.62V18c0-.34.28-.62.62-.62h2.49c.34 0 .62.28.62.62v1.86c0 .35-.28.62-.62.62zM11.8 20.48H9.3a.62.62 0 0 1-.62-.62V18c0-.34.28-.62.62-.62h2.48c.35 0 .62.28.62.62v1.86c0 .34-.27.62-.62.62zM16.76 20.48h-2.48a.62.62 0 0 1-.62-.62V18c0-.34.27-.62.62-.62h2.48c.34 0 .62.28.62.62v1.86c0 .34-.28.62-.62.62zM21.72 20.48h-2.48a.62.62 0 0 1-.62-.62V18c0-.34.28-.62.62-.62h2.48c.35 0 .63.28.63.62v1.86c0 .34-.28.62-.63.62z"
      fill="#E4EAF6"
    />
    <path
      d="M26.69 20.48h-2.48a.62.62 0 0 1-.62-.62V18c0-.34.27-.62.62-.62h2.48c.34 0 .62.28.62.62v1.86c0 .35-.28.62-.62.62zM31.65 20.48h-2.48a.62.62 0 0 1-.62-.62V18c0-.34.28-.62.62-.62h2.48c.35 0 .62.28.62.62v1.86c0 .34-.27.62-.62.62z"
      fill="#D7DEED"
    />
    <path
      d="M6.83 24.83H4.34a.62.62 0 0 1-.62-.62v-1.86c0-.35.28-.63.62-.63h2.49c.34 0 .62.28.62.63v1.86c0 .34-.28.62-.62.62zM11.8 24.83H9.3a.62.62 0 0 1-.62-.62v-1.87c0-.34.28-.62.62-.62h2.48c.35 0 .62.28.62.62v1.87c0 .34-.27.62-.62.62zM16.76 24.83h-2.48a.62.62 0 0 1-.62-.62v-1.87c0-.34.27-.62.62-.62h2.48c.34 0 .62.28.62.62v1.87c0 .34-.28.62-.62.62zM21.72 24.83h-2.48a.62.62 0 0 1-.62-.62v-1.87c0-.34.28-.62.62-.62h2.48c.35 0 .63.28.63.62v1.87c0 .34-.28.62-.63.62z"
      fill="#E4EAF6"
    />
    <path
      d="M26.69 24.83h-2.48a.62.62 0 0 1-.62-.62v-1.86c0-.35.27-.63.62-.63h2.48c.34 0 .62.28.62.63v1.86c0 .34-.28.62-.62.62zM31.65 24.83h-2.48a.62.62 0 0 1-.62-.62v-1.87c0-.34.28-.62.62-.62h2.48c.35 0 .62.28.62.62v1.87c0 .34-.27.62-.62.62z"
      fill="#D7DEED"
    />
    <path
      d="M6.83 29.17H4.34a.62.62 0 0 1-.62-.62V26.7c0-.34.28-.62.62-.62h2.49c.34 0 .62.28.62.62v1.86c0 .34-.28.62-.62.62zM11.8 29.17H9.3a.62.62 0 0 1-.62-.62V26.7c0-.34.28-.62.62-.62h2.48c.35 0 .62.28.62.62v1.86c0 .34-.27.62-.62.62zM16.76 29.17h-2.48a.62.62 0 0 1-.62-.62V26.7c0-.34.27-.62.62-.62h2.48c.34 0 .62.28.62.62v1.86c0 .34-.28.62-.62.62zM21.72 29.17h-2.48a.62.62 0 0 1-.62-.62V26.7c0-.34.28-.62.62-.62h2.48c.35 0 .63.28.63.62v1.86c0 .34-.28.62-.63.62z"
      fill="#E4EAF6"
    />
    <path
      d="M26.69 29.17h-2.48a.62.62 0 0 1-.62-.62V26.7c0-.34.27-.62.62-.62h2.48c.34 0 .62.28.62.62v1.86c0 .34-.28.62-.62.62zM31.65 29.17h-2.48a.62.62 0 0 1-.62-.62V26.7c0-.34.28-.62.62-.62h2.48c.35 0 .62.28.62.62v1.86c0 .34-.27.62-.62.62z"
      fill="#D7DEED"
    />
    <path
      d="M18 36a8.69 8.69 0 1 0 0-17.38A8.69 8.69 0 0 0 18 36z"
      fill="#AFB9D2"
    />
    <path
      d="M18 34.76a7.45 7.45 0 1 0 0-14.9 7.45 7.45 0 0 0 0 14.9z"
      fill="#fff"
    />
    <path
      d="M18 31.66a4.34 4.34 0 1 0 0-8.7 4.34 4.34 0 0 0 0 8.7z"
      fill="#EFF2FA"
    />
    <path
      d="M18 19.24a.62.62 0 0 0-.62.62v.62a.62.62 0 0 0 1.24 0v-.62a.62.62 0 0 0-.62-.62zM18 33.52a.62.62 0 0 0-.62.62v.62a.62.62 0 0 0 1.24 0v-.62a.62.62 0 0 0-.62-.62zM26.07 27.31a.62.62 0 0 0-.62-.62h-.62a.62.62 0 0 0 0 1.24h.62c.34 0 .62-.28.62-.62zM11.8 27.31a.62.62 0 0 0-.63-.62h-.62a.62.62 0 0 0 0 1.24h.62c.35 0 .62-.28.62-.62z"
      fill="#707487"
    />
    <path
      d="M18 29.8a.62.62 0 0 1-.62-.63v-6.2a.62.62 0 0 1 1.24 0v6.2c0 .35-.28.62-.62.62z"
      fill="#5B5D6E"
    />
    <path
      d="M15.52 27.31c0-.34.28-.62.62-.62h6.2a.62.62 0 0 1 0 1.24h-6.2a.62.62 0 0 1-.62-.62z"
      fill="#5B5D6E"
    />
    <path
      d="M18 28.55a1.24 1.24 0 1 0 0-2.48 1.24 1.24 0 0 0 0 2.48z"
      fill="#C7CFE2"
    />
  </svg>
);
export default WallCalendarIcon;
