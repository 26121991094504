import React from 'react';

const GroupIcon = props => (
  <svg
    width="38"
    height="38"
    fill="none"
    {...props}
  >
    <path
      d="M34.923 8.569h-7.301a.749.749 0 0 0-.75.745v4.813c0 .466.38.844.85.844 6.77 0 6.3-.045 6.495.138l1.073 1.007a.226.226 0 0 0 .382-.164V9.314a.749.749 0 0 0-.75-.745z"
      fill="#F94A46"
    />
    <path
      d="M2.707 8.569h7.301c.412 0 .75.335.75.745v4.813c0 .466-.38.844-.85.844-6.567 0-6.28-.065-6.495.138L2.34 16.116a.226.226 0 0 1-.382-.164V9.314a.75.75 0 0 1 .75-.745z"
      fill="#FFB70D"
    />
    <path
      d="M23.389 0h-9.146c-.658 0-1.197.536-1.197 1.191v6.186c0 .655.539 1.192 1.197 1.192h3.295l.93 1.338c.19.273.5.273.689 0l.93-1.338h3.302c.659 0 1.198-.537 1.198-1.192V1.191c0-.655-.54-1.191-1.198-1.191z"
      fill="#70B5E8"
    />
    <path
      d="M29.144 14.127V9.314c0-.41.337-.745.749-.745h-2.271a.75.75 0 0 0-.75.745v4.813c0 .466.38.844.85.844h2.271a.847.847 0 0 1-.849-.844zM33.87 14.971c.34 0 .39.232 1.42 1.145a.226.226 0 0 0 .381-.164v-.98h-1.802z"
      fill="#ED3E3E"
    />
    <path
      d="M4.23 9.314c0-.41.337-.745.75-.745H2.707a.749.749 0 0 0-.749.745v6.638c0 .198.237.3.382.164 1.03-.913 1.079-1.145 1.42-1.145h.47V9.314z"
      fill="#F2A306"
    />
    <path
      d="M15.317 7.377V1.191c0-.655.54-1.191 1.198-1.191h-2.272c-.658 0-1.197.536-1.197 1.191v6.186c0 .655.539 1.192 1.197 1.192h2.272a1.198 1.198 0 0 1-1.198-1.192zM19.809 8.569h-2.272l.93 1.339c.19.272.5.272.69 0l.79-1.14-.138-.2z"
      fill="#52A5D8"
    />
    <path
      d="M28.465 12.111a.565.565 0 0 1 .158-.787.571.571 0 0 1 .876.362c.095.502-.697.947-1.034.425zM33.57 12.364H31.12a.57.57 0 0 1-.57-.568.57.57 0 0 1 .57-.568h2.448a.57.57 0 0 1 .57.568.57.57 0 0 1-.57.568z"
      fill="#F48C8C"
    />
    <path
      d="M3.508 12.11a.566.566 0 0 1 .072-.715c.345-.354.974-.111.974.4 0 .517-.735.78-1.046.316zM8.612 12.364H6.164a.57.57 0 0 1-.57-.568.57.57 0 0 1 .57-.568h2.448a.57.57 0 0 1 .57.568.57.57 0 0 1-.57.568z"
      fill="#F9D07D"
    />
    <path
      d="M19.992 6.386h-4.477a.57.57 0 0 1-.57-.568.57.57 0 0 1 .57-.568h4.477a.57.57 0 0 1 .57.568.57.57 0 0 1-.57.568zM22.287 6.386h-.012a.57.57 0 0 1-.571-.568.57.57 0 0 1 .57-.568h.013a.57.57 0 0 1 .57.568.57.57 0 0 1-.57.568zM22.287 3.66h-4.476a.57.57 0 0 1-.571-.567.57.57 0 0 1 .57-.568h4.477a.57.57 0 0 1 .571.568.57.57 0 0 1-.57.567zM15.528 3.66h-.013a.57.57 0 0 1-.57-.567.57.57 0 0 1 .57-.568h.013a.57.57 0 0 1 .57.568.57.57 0 0 1-.57.567z"
      fill="#9FD3F4"
    />
    <path
      d="M35.947 23.371c-.294-5.765-8.532-6.662-10.075-1.098-.085.308-.14.595-.169.952-.018.226-.02 1.38-.019 2.074h10.27c0-1.799 0-1.776-.007-1.928z"
      fill="#93482B"
    />
    <path
      d="M29.554 22.273c.496-1.788 1.683-2.907 3.076-3.414-3.229-1.147-6.65.844-6.927 4.365-.018.227-.02 1.381-.019 2.075h3.683c-.003-1.97-.016-2.29.187-3.026z"
      fill="#873921"
    />
    <path
      d="M36.218 37.586c.899 0 1.584-.801 1.44-1.684l-.36-2.2c-.104-.52-.163-1.384-1.163-1.802-.093-.039-.306-.097-.302-.096-4.387-1.542-.35-.123-2.29-.802l-.971-.599v-1.15c-1.043.522-2.323.562-3.389.072l.001 1.078-1 .609-.514.176h-.001c-.008.003-2.116.746-1.977.684-.553.206-1.021.658-1.087 1.292-.029.276-.39 2.289-.61 3.502-.086.48.285.92.775.92h11.448z"
      fill="#EFEFEF"
    />
    <path
      d="M30.82 31.988l1.973-1.449-.221-.136v-1.15c-1.043.522-2.323.562-3.389.072l.001 1.078-.224.136 1.86 1.448z"
      fill="#EAB883"
    />
    <path
      d="M30.83 29.668a3.846 3.846 0 0 1-1.646-.343v1.078l-.224.137 1.828 1.423h.042v-2.295z"
      fill="#E0A773"
    />
    <path
      d="M29.184 30.404l1.635 1.584-1.46 1.01a.388.388 0 0 1-.589-.196l-.585-1.79.999-.608z"
      fill="#DDD"
    />
    <path
      d="M32.571 30.403l-1.752 1.585 1.46 1.01a.388.388 0 0 0 .589-.196l.675-1.8-.972-.599z"
      fill="#DDD"
    />
    <path
      d="M31.477 34.227h-1.315l-.903 3.362h3.121l-.903-3.362z"
      fill="#EF3C3C"
    />
    <path
      d="M30.82 31.988l-1.226.848.567 1.391h1.316l.568-1.39-1.226-.85z"
      fill="#F94A46"
    />
    <path
      d="M31.477 34.227h-1.315l-.903 3.362h1.316l.903-3.36v-.002z"
      fill="#DD2F2F"
    />
    <path
      d="M30.62 32.126l-1.026.71.567 1.391h1.316l-.857-2.101z"
      fill="#ED3E3E"
    />
    <path
      d="M27.227 24.705c-.891-.316-1.927.272-1.927 1.13 0 .856 1.035 1.444 1.927 1.128v-2.258zM34.413 24.705c.891-.316 1.927.272 1.927 1.13 0 .856-1.036 1.444-1.927 1.128v-2.258z"
      fill="#EAB883"
    />
    <path
      d="M25.433 25.324c-.548.994.651 2.044 1.793 1.64v-1.022c-.699.248-1.486-.06-1.793-.618zM34.413 25.943v1.02c1.142.406 2.342-.644 1.794-1.639-.307.558-1.094.866-1.794.619z"
      fill="#E0A773"
    />
    <path
      d="M34.38 23.37c.096.008.169.087.169.182 0 3.123.002 2.666-.005 2.812-.214 4.187-6.197 4.838-7.318.797a3.492 3.492 0 0 1-.123-.691c-.02-.262-.012-1.686-.012-1.682 0-.727.649-1.282 1.373-1.178 1.14.164 2.803.1 4.254-1.134a.31.31 0 0 1 .471.085c.18.322.538.745 1.192.808z"
      fill="#F7CF9C"
    />
    <path
      d="M29.378 26.364c-.008-.143-.006.299-.006-2.689-.326 0-.633-.025-.908-.065a1.196 1.196 0 0 0-1.373 1.178c0-.004-.009 1.42.012 1.682.193 2.462 2.523 3.938 4.858 3.227-1.36-.413-2.493-1.553-2.583-3.333z"
      fill="#EAB883"
    />
    <path
      d="M35.422 36.203a.57.57 0 0 0-.571-.567.57.57 0 0 0-.571.567v1.383h1.141v-1.383z"
      fill="#DDD"
    />
    <path
      d="M11.809 23.371c-.294-5.765-8.532-6.662-10.075-1.098-.226.816-.19 1.265-.188 3.026h10.27c0-1.799 0-1.776-.007-1.928z"
      fill="#93482B"
    />
    <path
      d="M5.417 22.273c.495-1.788 1.683-2.907 3.075-3.414-3.229-1.147-6.65.844-6.926 4.365-.018.227-.02 1.381-.02 2.075H5.23c-.003-1.97-.016-2.29.188-3.026z"
      fill="#873921"
    />
    <path
      d="M12.08 37.586c.898 0 1.583-.801 1.439-1.684l-.36-2.2c-.103-.52-.162-1.384-1.162-1.802-.094-.039-.306-.097-.302-.096-4.387-1.542-.35-.123-2.29-.802l-.972-.599v-1.15c-1.042.522-2.322.562-3.388.072v1.078l-.998.609-.515.176H3.53c-.008.003-2.116.746-1.977.684C1 32.078.532 32.53.467 33.164c-.022.213-.242 1.457-.442 2.572a1.576 1.576 0 0 0 1.557 1.85H12.08z"
      fill="#EFEFEF"
    />
    <path
      d="M1.3 35.736s.419-2.813.44-3.026c.058-.555.42-.97.89-1.205-1.099.384-1.083.37-1.076.367C1 32.078.532 32.53.467 33.164c-.022.213-.242 1.458-.442 2.572a1.576 1.576 0 0 0 1.557 1.85h1.274c-.985 0-1.73-.885-1.557-1.85z"
      fill="#DDD"
    />
    <path
      d="M6.682 31.988l1.973-1.449-.221-.136v-1.15c-1.043.522-2.323.562-3.388.072v1.078l-.223.136 1.859 1.448z"
      fill="#EAB883"
    />
    <path
      d="M6.692 29.668a3.847 3.847 0 0 1-1.646-.343v1.078l-.223.137 1.827 1.423h.042v-2.295z"
      fill="#E0A773"
    />
    <path
      d="M5.045 30.404l1.636 1.584-1.46 1.01a.388.388 0 0 1-.589-.196l-.586-1.79 1-.608zM8.434 30.403l-1.752 1.585 1.46 1.01a.388.388 0 0 0 .589-.196l.674-1.8-.971-.599z"
      fill="#DDD"
    />
    <path
      d="M7.34 34.227H6.022l-.902 3.362h3.12l-.902-3.362z"
      fill="#EF3C3C"
    />
    <path
      d="M6.682 31.988l-1.226.848.568 1.391h1.315l.568-1.39-1.225-.85z"
      fill="#F94A46"
    />
    <path
      d="M7.34 34.227H6.022l-.902 3.362h1.316l.902-3.36v-.002z"
      fill="#DD2F2F"
    />
    <path
      d="M6.482 32.126l-1.026.71.568 1.391h1.315l-.857-2.101z"
      fill="#ED3E3E"
    />
    <path
      d="M3.089 24.705c-.892-.316-1.927.272-1.927 1.13 0 .856 1.035 1.444 1.927 1.128v-2.258zM10.275 24.705c.892-.316 1.927.272 1.927 1.13 0 .856-1.035 1.444-1.927 1.128v-2.258z"
      fill="#EAB883"
    />
    <path
      d="M1.295 25.324c-.548.994.651 2.044 1.793 1.64v-1.022c-.699.248-1.486-.06-1.793-.618zM10.275 25.943v1.02c1.143.406 2.342-.644 1.794-1.639-.307.558-1.094.866-1.794.619z"
      fill="#E0A773"
    />
    <path
      d="M10.242 23.37c.095.008.168.087.168.182 0 3.123.002 2.666-.005 2.812-.213 4.187-6.197 4.838-7.318.797a3.488 3.488 0 0 1-.122-.691c-.021-.262-.012-1.686-.012-1.682 0-.727.649-1.282 1.372-1.178 1.14.164 2.803.1 4.255-1.134a.31.31 0 0 1 .47.085c.18.322.538.745 1.192.808z"
      fill="#F7CF9C"
    />
    <path
      d="M5.24 26.364c-.008-.143-.006.299-.006-2.689-.327 0-.633-.025-.909-.065a1.196 1.196 0 0 0-1.372 1.178c0-.004-.009 1.42.012 1.682.193 2.462 2.523 3.938 4.857 3.227-1.359-.413-2.492-1.553-2.583-3.333z"
      fill="#EAB883"
    />
    <path
      d="M3.3 37.586v-1.383a.57.57 0 0 0-.571-.567.57.57 0 0 0-.57.567v1.383h1.14z"
      fill="#DDD"
    />
    <path
      d="M25.166 19.682c-.37-7.248-10.726-8.375-12.667-1.38-.284 1.026-.24 1.59-.236 3.805h12.912c0-2.263 0-2.234-.01-2.425z"
      fill="#93482B"
    />
    <path
      d="M16.549 18.301c.657-2.368 2.279-3.804 4.151-4.388-3.183-.971-7.085.364-8.201 4.388-.284 1.027-.24 1.59-.236 3.806h4.05c-.004-2.478-.02-2.881.236-3.806z"
      fill="#873921"
    />
    <path
      d="M26.287 37.554c.72 0 1.27-.642 1.154-1.35l-.578-3.533c-.13-.653-.204-1.74-1.462-2.265-.122-.051-.37-.118-.382-.122-2.98-1.047-2.865-1-2.883-1.012l-1.176-.724a.082.082 0 0 1-.039-.07v-1.27a.082.082 0 0 0-.117-.074c-1.251.584-2.751.63-4.027.085a.082.082 0 0 0-.115.075c0 1.289.017 1.22-.038 1.254-1.298.79-1.213.74-1.226.744l-.639.219h-.002c-.008.003-2.655.936-2.485.86-.696.258-1.284.827-1.367 1.624-.033.325-.432 2.558-.71 4.093a1.248 1.248 0 0 0 1.233 1.467h14.86z"
      fill="#EFEFEF"
    />
    <path
      d="M11.637 36.088s.676-4.298.71-4.623c.067-.651.466-1.156 1.029-1.469-1.129.394-1.112.378-1.104.374-.696.259-1.284.828-1.367 1.625-.033.325-.432 2.558-.71 4.093a1.248 1.248 0 0 0 1.233 1.467h1.442c-.78 0-1.371-.703-1.233-1.467z"
      fill="#DDD"
    />
    <path
      d="M18.72 30.516l2.48-1.82-.278-.172v-1.447c-1.311.657-2.92.707-4.26.091l.001 1.356-.281.171 2.337 1.82z"
      fill="#EAB883"
    />
    <path
      d="M18.72 30.515V27.6a4.844 4.844 0 0 1-2.058-.432l.001 1.355-.281.172 2.337 1.82z"
      fill="#E0A773"
    />
    <path
      d="M16.663 28.524l2.056 1.992-1.835 1.27a.488.488 0 0 1-.74-.247l-.737-2.25 1.256-.765z"
      fill="#DDD"
    />
    <path
      d="M20.92 28.524l-2.202 1.992 1.835 1.27c.268.186.64.062.741-.247l.849-2.263-1.222-.752z"
      fill="#DDD"
    />
    <path
      d="M19.547 33.329l-1.658-.004-1.133 4.233h3.925l-1.134-4.23z"
      fill="#EF3C3C"
    />
    <path
      d="M19.547 33.329l-1.658-.004-1.133 4.233h1.712l1.096-4.163-.017-.066z"
      fill="#DD2F2F"
    />
    <path
      d="M14.201 21.36c-1.12-.397-2.422.342-2.422 1.42 0 1.078 1.302 1.817 2.422 1.42v-2.84zM23.238 21.36c1.12-.398 2.422.34 2.422 1.419 0 1.078-1.302 1.817-2.422 1.42v-2.84z"
      fill="#EAB883"
    />
    <path
      d="M11.965 22.103c-.735 1.252.781 2.611 2.236 2.095v-1.352c-.865.307-1.836-.063-2.236-.743zM23.238 22.846v1.352c1.455.516 2.971-.843 2.236-2.095-.4.68-1.371 1.05-2.236.743z"
      fill="#E0A773"
    />
    <path
      d="M23.196 19.68c.12.011.211.11.211.23 0 3.926.003 3.352-.006 3.535-.269 5.264-7.791 6.083-9.201 1.002a4.397 4.397 0 0 1-.154-.869c-.026-.33-.015-2.12-.015-2.114 0-.914.815-1.612 1.725-1.481 1.433.205 3.525.125 5.35-1.426a.388.388 0 0 1 .592.106c.226.405.675.938 1.498 1.017z"
      fill="#F7CF9C"
    />
    <path
      d="M23.196 19.68c.12.011.211.11.211.23 0 3.926.003 3.352-.006 3.535-.269 5.264-7.791 6.083-9.201 1.002a4.397 4.397 0 0 1-.154-.869c-.026-.33-.015-2.12-.015-2.114 0-.914.815-1.612 1.725-1.481 1.433.205 3.525.125 5.35-1.426a.388.388 0 0 1 .592.106c.226.405.675.938 1.498 1.017z"
      fill="#F7CF9C"
    />
    <path
      d="M16.318 23.445c-.009-.181-.007.377-.007-3.402a8.327 8.327 0 0 1-.555-.06 1.504 1.504 0 0 0-1.725 1.481c0-.006-.011 1.784.015 2.114.227 2.895 2.873 4.84 5.813 4.137-1.836-.439-3.42-1.906-3.54-4.27z"
      fill="#EAB883"
    />
    <path
      d="M18.718 30.516l-1.54 1.067.713 1.748h1.654l.714-1.748-1.54-1.067z"
      fill="#F94A46"
    />
    <path
      d="M18.469 30.689l-1.292.893.714 1.749h1.654l.001-.003-1.077-2.64z"
      fill="#ED3E3E"
    />
    <path
      d="M13.154 35.35a.57.57 0 0 0-.57.568v1.637h1.14v-1.637a.57.57 0 0 0-.57-.568zM24.284 35.35a.57.57 0 0 0-.571.567v1.638h1.141v-1.638a.57.57 0 0 0-.57-.567z"
      fill="#DDD"
    />
  </svg>
);

export default GroupIcon;
