import React, { Component } from 'react';
import './AppDrawerButton.scss';
import HeaderDropDown from '../HeaderDropDown/HeaderDropDown';
import { AppDrawerIcon } from '../../assets/react-icons';


export default class AppDrawerButton extends Component {
  render() {
    return (
      <div id="app-dropdown-button" className={this.props.className}>
        <HeaderDropDown dropDownType='app-picker' idName="app-dropdown">
          <AppDrawerIcon width="24px" />
        </HeaderDropDown>
      </div>
    );
  }
}
