import React from 'react';

const SmartphoneIcon = props => (
  <svg
    width="34"
    height="36"
    fill="none"
    {...props}
  >
    <path
      d="M7.29 2.39c0-.43.12-.82.33-1.15L1.53 2.91A2.12 2.12 0 0 0 .07 5.5l7.17 27.35c0 .03.05.03.05 0V2.39zM26.71 1.77c0-.43-.12-.82-.33-1.15l6.09 1.67c1.1.3 1.75 1.46 1.46 2.59l-7.17 27.35c0 .03-.05.03-.05 0V1.77z"
      fill="#E7ECED"
    />
    <path
      d="M24.65 36H9.35a2.1 2.1 0 0 1-2.06-2.11V2.1A2.1 2.1 0 0 1 9.35 0h15.3a2.1 2.1 0 0 1 2.06 2.11V33.9A2.1 2.1 0 0 1 24.65 36z"
      fill="#424A60"
    />
    <path
      d="M26.71 3.72H7.3v24.83H26.7V3.72z"
      fill="#A4E869"
    />
    <path
      d="M17 34.14c1 0 1.82-.84 1.82-1.86 0-1.03-.81-1.87-1.82-1.87-1 0-1.82.84-1.82 1.87 0 1.02.81 1.86 1.82 1.86zM17 2.48h-2.43a.61.61 0 0 1-.6-.62c0-.34.27-.62.6-.62H17c.34 0 .6.28.6.62 0 .35-.26.62-.6.62zM19.43 2.48h-.6a.61.61 0 0 1-.62-.62c0-.34.28-.62.61-.62h.6c.34 0 .62.28.62.62 0 .35-.28.62-.61.62z"
      fill="#262B35"
    />
  </svg>
);
export default SmartphoneIcon;




