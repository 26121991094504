import React from 'react';

const SettingsIcon = props => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.572 9.735c.03-.24.053-.48.053-.735s-.023-.495-.053-.735l1.583-1.238a.378.378 0 0 0 .09-.48l-1.5-2.594a.377.377 0 0 0-.458-.165l-1.867.75a5.48 5.48 0 0 0-1.268-.736l-.285-1.987A.366.366 0 0 0 10.5 1.5h-3a.366.366 0 0 0-.368.315l-.285 1.988a5.762 5.762 0 0 0-1.267.734l-1.868-.75a.366.366 0 0 0-.457.166l-1.5 2.595a.37.37 0 0 0 .09.48l1.582 1.237c-.03.24-.052.487-.052.735s.022.495.052.735l-1.582 1.238a.378.378 0 0 0-.09.48l1.5 2.595c.09.165.292.225.457.165l1.868-.75c.39.3.81.547 1.267.735l.285 1.987c.023.18.18.315.368.315h3a.366.366 0 0 0 .367-.315l.285-1.987a5.762 5.762 0 0 0 1.268-.736l1.867.75c.173.068.368 0 .458-.165l1.5-2.595a.378.378 0 0 0-.09-.48l-1.583-1.237zM9 11.625A2.628 2.628 0 0 1 6.375 9 2.628 2.628 0 0 1 9 6.375 2.628 2.628 0 0 1 11.625 9 2.628 2.628 0 0 1 9 11.625z"
      fill="currentColor"
    />
  </svg>
);

export default SettingsIcon;
