import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import rootReducer from './reducers/root.reducer';

export const history = createBrowserHistory();

const defaultState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const store = createStore(rootReducer, defaultState, composeEnhancers(applyMiddleware(promise, thunk)));

  if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept('./reducers/root.reducer', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export const store = configureStore();
