import * as types from '../actions/ActionTypes';

const INITIAL_STATE = {
  authUser: null
};

const applySetAuthUser = (state, action) => ({
  ...state,
  authUser: action.authUser
});

export function sessionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_USER_SET: {
      return applySetAuthUser(state, action);
    }
    default:
      return state;
  }
}
