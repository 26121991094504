import React, { Component } from 'react';
import './UserButton.scss';
import HeaderDropDown from '../HeaderDropDown/HeaderDropDown';

class UserButton extends Component {
  render() {
    return (
      <div id="user-dropdown-button" className={this.props.className}>
        <HeaderDropDown dropDownType="user" idName="user-dropdown">
          <div className="user-avatar">{this.props.authUser && `${this.props.authUser.fn[0]}${this.props.authUser.ln[0]}`}</div>
        </HeaderDropDown>
      </div>
    );
  }
}

export default UserButton;
