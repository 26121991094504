import React from 'react';

const LightBulbIcon = props => (
  <svg
    width="27"
    height="38"
    fill="none"
    {...props}
  >
    <path
      d="M15.144 34.065H11.65a.585.585 0 0 0-.583.587v.588a2.342 2.342 0 0 0 2.33 2.349 2.342 2.342 0 0 0 2.33-2.35v-.587a.585.585 0 0 0-.583-.587z"
      fill="#868491"
    />
    <path
      d="M13.396 34.065H11.65a.585.585 0 0 0-.583.587v.588a2.342 2.342 0 0 0 2.33 2.349v-3.524z"
      fill="#5C546A"
    />
    <path
      d="M22.568 3.663A13.225 13.225 0 0 0 12.54.028C5.725.455.22 6.188.007 13.078c-.157 5.107 2.502 9.807 6.94 12.27a3.504 3.504 0 0 1 1.79 3.07v4.472a2.342 2.342 0 0 0 2.33 2.35h4.659a2.342 2.342 0 0 0 2.33-2.35v-4.472c0-1.28.69-2.46 1.802-3.078 4.277-2.379 6.934-6.912 6.934-11.831 0-3.713-1.54-7.302-4.224-9.846z"
      fill="#FFCD00"
    />
    <path
      d="M13.396 22.906c5.146 0 9.319-4.207 9.319-9.397s-4.173-9.397-9.32-9.397c-5.146 0-9.318 4.207-9.318 9.397s4.172 9.397 9.319 9.397z"
      fill="#FFE671"
    />
    <path
      d="M15.434 17.033c-.775 0-1.17-.456-1.459-.788-.254-.292-.355-.387-.582-.387-.226 0-.327.095-.58.387-.272.313-.683.788-1.457.788s-1.185-.475-1.457-.788c-.253-.292-.353-.387-.58-.387a.585.585 0 0 1-.582-.587c0-.325.26-.587.583-.587.773 0 1.184.474 1.456.788.254.292.354.386.58.386.226 0 .327-.094.58-.386.272-.314.683-.788 1.457-.788s1.17.455 1.458.787c.254.293.356.387.582.387.227 0 .328-.094.582-.386.273-.314.684-.788 1.459-.788.322 0 .582.262.582.587 0 .324-.26.587-.582.587-.227 0-.329.095-.583.387-.272.314-.683.788-1.457.788z"
      fill="#fff"
    />
    <path
      d="M11.649 28.192a.584.584 0 0 1-.569-.462L8.168 14.22a.582.582 0 1 1 1.139-.25l2.912 13.509a.588.588 0 0 1-.57.712zM15.145 28.192a.588.588 0 0 1-.57-.712l2.912-13.509a.581.581 0 0 1 .694-.449c.314.07.513.382.445.7l-2.912 13.507a.584.584 0 0 1-.569.463z"
      fill="#5C546A"
    />
    <path
      d="M8.637 27.605c.062.263.1.535.1.813v4.472a2.342 2.342 0 0 0 2.33 2.35h4.66a2.342 2.342 0 0 0 2.329-2.35v-4.472c0-.278.038-.55.1-.813H8.638z"
      fill="#B4B6BC"
    />
    <path
      d="M13.396 27.605H8.637c.062.263.1.535.1.813v4.472a2.342 2.342 0 0 0 2.33 2.35h2.329v-7.636zM18.055 29.367h-4.659v1.174h4.66v-1.174zM18.055 31.716h-4.659v1.175h4.66v-1.175z"
      fill="#868491"
    />
    <path
      d="M13.396 29.367h-4.66v1.174h4.66v-1.174zM13.396 31.716h-4.66v1.175h4.66v-1.175z"
      fill="#5C546A"
    />
  </svg>
);

export default LightBulbIcon;
