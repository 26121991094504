import React from 'react';

const LabelIcon = props => (
  <svg viewBox="0 0 24 24"  {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.63 5.84A2 2 0 0 0 16 5L5 5.01A2 2 0 0 0 3 7v10a2 2 0 0 0 2 1.99L16 19a2 2 0 0 0 1.63-.84L22 12l-4.37-6.16zM16 17H5V7h11l3.55 5L16 17z"
      fill="currentColor"
    />
  </svg>
);

export default LabelIcon;
