import './Header.scss';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import stickyBits from 'stickybits';
import { DesktopHeader } from '../';

export class Header extends Component {

  static get propTypes() {
    return {
      className: PropTypes.string,
      children: PropTypes.node,
      style: PropTypes.object
    };
  }

  static get defaultProps() {
    return {
      className: '',
      children: null,
      style: null
    };
  }

  state = {
    mobile: false,
  }

  componentDidMount = () => {

    setTimeout(() => { //necessary hack because the height of sticky-parent is calculated before the data is fully loaded
      stickyBits('#header-container', {
        useStickyClasses: true,
        parentClass: 'sticky-parent',
        stickyClass: 'sticky',
        stuckClass: 'stuck',
      });
    }, 100);
  }

  componentWillUnmount = () => {
    //clean up classes
    document.getElementById('root').classList.remove('sticky-parent');
  }

  handleResize = ({ width }) => {
    this.setState({
      mobile: width <= 800
    });
  };

  render() {
    const { children, className, style, authUser } = this.props;

    return (
      <Fragment>
        <div id="header-container" className={`${className}`} style={style}>
          <DesktopHeader authUser={authUser} />
          {children &&
            <div className="header-child-container">
              {children}
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

export default Header;
