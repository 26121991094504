import React from 'react';

const LotIcon = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6V3a9 9 0 1 1-4.37 1.13l1.45 2.62A6 6 0 1 0 12 6z"
      fill="currentColor"
    />
  </svg>
);

export default LotIcon;
