import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { checkAuth } from './actions/authUser.action';
import { connect } from 'react-redux';
import Layout from './containers/Layout/Layout';
import { SessionDialog } from './components';

class App extends Component {

  state = {
    error: null,
    sessionModalVisible: false
  };

  componentDidMount = () => {
    this.unlisten = this.props.history.listen(location => {
      this.onRouteChanged(location);
    });

    this.checkLoggedIn();
  }

  componentWillUnmount() {
    this.unlisten();
    clearTimeout(this.timer);
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    this.setState({
      error: error
    });
  }

  checkLoggedIn = () => {
    // Uncomment this block to enable authentication
    const { checkAuth } = this.props;

    if (!checkAuth()) {
      window.location.replace(`${process.env.REACT_APP_LOGIN_URL}?redirectUrl=${window.location.href}`);

    } else {
      this.startTimeout();
    }
  };


  onRouteChanged() {
    this.checkLoggedIn();
  }

  hide = () => {
    this.setState({ sessionModalVisible: false });
  }

  show = () => {
    this.setState({ sessionModalVisible: true });
  }

  startTimeout = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(this.show, 29 * 60 * 1000); // 29 minutes
  }

  render() {
    return (
      <Fragment>
        <Layout {...this.props} />
        <SessionDialog onHide={this.hide} visible={this.state.sessionModalVisible} onContinue={this.startTimeout} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.session.authUser
});

export default withRouter(
  connect(
    mapStateToProps,
    { checkAuth }
  )(App)
);
