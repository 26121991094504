import React from 'react';

const CashRegisterIcon = props => (

  <svg
    width="42"
    height="42"
    fill="none"
    {...props}
  >
    <path
      d="M21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21z"
      fill="#EE4A68"
    />
    <path
      d="M31.906 10.906H10.095v11.52h21.81v-11.52z"
      fill="#4F5565"
    />
    <path
      d="M31.906 10.906H10.095v.475h21.81v-.475z"
      fill="#646B79"
    />
    <path
      d="M29.165 12.947H12.807v7.41h16.358v-7.41z"
      fill="#6EB1E1"
    />
    <path
      d="M31.906 22.426H10.095l-2.88 10.402h27.57l-2.88-10.402z"
      fill="#646B79"
    />
    <path
      d="M34.758 32.829H7.215v1.761h27.543V32.83z"
      fill="#DEDEDF"
    />
    <path
      d="M17.731 23.769h-3.215v1.761h3.215V23.77zM22.623 23.769h-3.216v1.761h3.216V23.77zM27.516 23.769h-3.215v1.761h3.215V23.77zM17.73 26.733h-3.216v1.762h3.215v-1.762zM22.623 26.733h-3.216v1.762h3.216v-1.762zM27.516 26.733h-3.215v1.762h3.215v-1.762zM17.73 29.725h-3.216v1.761h3.215v-1.761zM22.623 29.725h-3.216v1.761h3.216v-1.761zM27.516 29.725h-3.215v1.761h3.215v-1.761z"
      fill="#F1F3F7"
    />
    <path
      d="M26.62 2.936l-1.146 1.147-1.119-1.147-1.118 1.147-1.118-1.147L21 4.083l-1.119-1.147-1.118 1.147-1.119-1.147-1.118 1.147-1.146-1.147-1.063 1.09v6.88h13.366v-6.88l-1.063-1.09z"
      fill="#FFFFFC"
    />
    <path
      d="M19.238 5.705h-3.523v.643h3.523v-.643zM22.763 5.705h-2.461v.643h2.46v-.643zM26.286 5.705h-1.594v.643h1.594v-.643zM17.616 7.298h-1.901v.644h1.901v-.644zM22.37 7.298h-3.83v.644h3.83v-.644zM26.285 7.298h-3.188v.644h3.188v-.644zM20.3 8.892h-4.585v.643H20.3v-.643zM23.6 8.892h-2.46v.643h2.46v-.643zM26.285 8.892h-2.013v.643h2.013v-.643z"
      fill="#F1F3F7"
    />
    <path
      d="M22.37 17.644c0-.755-.615-1.37-1.37-1.37a.59.59 0 0 1-.587-.587A.59.59 0 0 1 21 15.1c.168 0 .308.056.42.195.14.168.39.168.559.028.167-.14.167-.391.027-.559a1.25 1.25 0 0 0-.615-.363v-.28a.382.382 0 0 0-.391-.392.382.382 0 0 0-.392.392v.28c-.559.167-.978.699-.978 1.314 0 .755.615 1.37 1.37 1.37a.59.59 0 0 1 .587.587.59.59 0 0 1-.587.587.503.503 0 0 1-.42-.195c-.14-.168-.391-.168-.559-.028-.168.14-.168.391-.028.559.168.168.364.308.616.364v.28c0 .223.167.39.39.39a.382.382 0 0 0 .392-.39v-.28c.56-.196.98-.7.98-1.315z"
      fill="#FCFCFD"
    />
  </svg>

);

export default CashRegisterIcon;
